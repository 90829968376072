<template>
  <div class="content">
    <div class="contantTop flex flex_b">
      <div class="tipTitle">系统管理/组织架构管理/部门管理</div>
      <!-- <div class="tipButton flex flex_ju_center flex_center" @click="add">新增</div> -->
      <div class="tipButton flex flex_ju_center flex_center" v-if="isShow" @click="addDislogQx = true">新增</div>
    </div>
    <div class="contant flex flex_row">
      <div class="leftContant">
        <el-tree class="tree" :props="defaultProps" :indent="0" @node-click="getById" :load="loadNode" lazy>
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ data.name }}</span>
            <span>
              <el-dropdown @command="handleCommand($event, data)">
                <i class="el-icon-setting"></i>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="edit">编辑部门</el-dropdown-item>
                  <el-dropdown-item command="del">删除部门</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </span>
          </span>
        </el-tree>
      </div>

      <el-dialog title="添加职位" :visible.sync="addDislogQx" width="30%" :close-on-click-modal="false" @close="addDislogQxs('ruleForm')">
        <div class="dialong-content">
          <div class="dialong-title">职位名称</div>
          <el-input maxlength="15" placeholder="请输入名称" v-model="menuForm.postName"></el-input>
        </div>
        <div class="dialong-content">
          <div class="dialong-title">排序</div>
          <el-input maxlength="15" type="number" placeholder="排序" v-model="menuForm.sort"></el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addDislogQxs">取 消</el-button>
          <el-button type="primary" @click="addUser()">确 定</el-button>
        </span>
      </el-dialog>

      <div class="rightContant">
        <el-table :data="tableData" style="width: 100%" :header-cell-style="{ background: '#17dbcb', color: '#fff' }">
          <el-table-column align="center" prop="postName" label="职位名称"> </el-table-column>
          <!-- <el-table-column align="center" prop="nickName" label="创建人"> </el-table-column> -->
          <el-table-column align="center" prop="createDate" label="创建时间"> </el-table-column>
          <el-table-column align="center" prop="address" label="操作">
            <template slot-scope="scope">
              <div class="flex flex_row flex_ju_center">
                <div class="border_center_left" @click="edit(scope.row)">编辑</div>
                <div class="color_font" @click="delData(scope.row.id, scope.$index)">删除</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- <div class="page flex flex_end">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="100"
            layout="prev, pager, next, jumper"
            :total="total"
            prev-text="上一页"
            next-text="下一页"
          >
          </el-pagination>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      menuForm: {
        deptId: '',
        sort: '',
        postName: '',
      },
      addDislogQx: false,
      isShow: false,
      currentPage: 1,
      total: 0,
      queryID: '',
      resolve: [],
      node: [],
      isTree: false,
      defaultProps: {
        children: 'zones',
        label: 'name',
      },
      queryData: {
        level: '',
        name: '',
        parent: '',
      },
      tableData: [], //人员列表
    }
  },
  mounted() {},
  methods: {
    addDislogQxs() {
      let data = {
        deptId: '',
        sort: '',
        postName: '',
      }
      this.menuForm = data
      this.addDislogQx = false
    },
    addUser() {
      let _that = this
      if (!_that.menuForm.postName) {
        _that.utils.error('请输入职位名称')
        return
      }
      if (!_that.menuForm.sort) {
        _that.utils.error('请输入排序')
        return
      }
      let data = _that.menuForm
      _that.$api.adddept(data).then((res) => {
        if (res.data.code == 200) {
          _that.tableData.push({
            postName: res.data.data.postName,
            sort: res.data.data.sort,
            id: res.data.data.id,
            deptId: res.data.data.deptId,
            createDate: res.data.data.createDate,
          })
          _that.addDislogQx = false
          _that.utils.success('新增成功')
        } else {
          _that.utils.error(res.data.message)
        }
      })
    },
    resetNode() {
      this.node.childNodes = []
      this.loadNode(this.node, this.resolve)
    },
    add() {
      this.$router.replace({
        path: '/department/addMent',
      })
    },
    edit(e) {
      let _that = this
      let data = JSON.stringify(e)
      _that.$router.push({
        path: '/department/editDept',
        query: { data: data },
      })
    },
    handleCommand(e, item) {
      let _that = this
      if (e == 'edit') {
        let data = JSON.stringify(item)
        _that.$router.push({
          path: '/department/editment',
          query: { data: data },
        })
      } else {
        _that.delMenu(item.id)
      }
    },
    // 删除部门
    delMenu(id) {
      let _that = this
      let data = {
        id: id,
      }
      this.$confirm('此操作将删除该菜单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'error',
      }).then(() => {
        _that.$api.delDept(data).then((res) => {
          if (res.data.code == 200) {
            _that.isTree = true
            _that.resetNode() //更新视图
            _that.utils.success('删除成功')
          } else {
            _that.utils.error(res.data.message)
          }
        })
      })
    },
    getById(e) {
      let _that = this
      if (e.parent != '-1') {
        _that.isShow = true
      } else {
         _that.isShow = false
      }
      _that.queryID = e.id
      _that.menuForm.deptId = e.id
      _that.getPeopleList()
    },
    // 获取部门人员列表
    getPeopleList() {
      let _that = this
      let data = {
        id: _that.queryID,
      }
      this.$api.deptPost(data).then((res) => {
        if (res.data.code == 200) {
          _that.tableData = res.data.data
          _that.total = res.data.data.total
        } else {
          this.utils.error(res.data.message)
        }
      })
    },
    // 获取父级部门 tree
    queryDeptList(resolve) {
      let _that = this
      let data = _that.queryData
      data.parent = '-1'
      //这里为啥要判断？？？
      // if (_that.isTree) {

      // }
      this.$api.deptList(data).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data
          if (data.length > 0) {
            _that.queryID = data[0].id
            _that.getPeopleList()
          }
          resolve(data)
        } else {
          this.utils.error(res.data.message)
        }
      })
    },
    delData(e, index) {
      let _that = this
      let data = {
        id: e,
      }
      _that.$api.delPost(data).then((res) => {
        if (res.data.code == 200) {
          _that.tableData.splice(index, 1)
          _that.utils.success('删除成功')
        } else {
          this.utils.error(res.data.message)
        }
      })
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        this.node = node
        this.resolve = resolve
        this.queryDeptList(resolve) //获取父节点数据
      }
      if (node.level >= 1) {
        this.getChildByList(node.data.id, resolve) //获取子节点数据
        return resolve([])
      }
    },

    // 获取子级部门 tree
    getChildByList(data, resolve) {
      let _that = this
      if (_that.isTree) {
        _that.queryData.parent = '-1'
      } else {
        _that.queryData.parent = data
      }
      let queryData = _that.queryData
      this.$api.deptList(queryData).then((res) => {
        if (res.data.code == 200) {
          let data = res.data.data
          resolve(data)
        } else {
          this.utils.error(res.data.message)
        }
      })
    },
    handleSizeChange(val) {
      let _that = this
      _that.currentPage = val
      _that.getPeopleList()
    },
    handleCurrentChange(val) {
      let _that = this
      _that.currentPage = val
      _that.getPeopleList()
    },
  },
}
</script>
<style lang="less" scoped>
.contant {
  min-height: 500px;
  .leftContant {
    width: 25%;
  }
  .rightContant {
    width: 75%;
  }
  .color_font {
    color: #f39517;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
.tree {
  .el-tree-node {
    position: relative;
    padding-left: 16px; // 缩进量
  }
  .el-tree-node__children {
    padding-left: 16px; // 缩进量
  }

  // 竖线
  .el-tree-node::before {
    content: '';
    height: 100%;
    width: 1px;
    position: absolute;
    left: -3px;
    top: -26px;
    border-width: 1px;
    border-left: 1px dashed #d6d7d8;
  }
  // 当前层最后一个节点的竖线高度固定
  .el-tree-node:last-child::before {
    height: 38px; // 可以自己调节到合适数值
  }

  // 横线
  .el-tree-node::after {
    content: '';
    width: 24px;
    height: 20px;
    position: absolute;
    left: -3px;
    top: 12px;
    border-width: 1px;
    border-top: 1px dashed #d6d7d8;
  }

  & > .el-tree-node::after {
    border-top: none;
  }
  & > .el-tree-node::before {
    border-left: none;
  }
  .el-tree-node__expand-icon {
    font-size: 16px;
    &.is-leaf {
      color: transparent;
    }
  }
}
.custom-tree-node {
  width: 100%;
}
.dialong-content {
  margin-bottom: 20px;
  .dialong-title {
    margin-bottom: 10px;
  }
}
</style>
